import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';

import { app } from '@shared/data/data.json';

@Component({
  selector: 'app-root',
  template: `
    <app-header></app-header>
    <router-outlet></router-outlet>
    <app-ts-logo></app-ts-logo>
    <app-footer></app-footer>
  `,
})
export class AppComponent {
  public constructor(private titleService: Title) {
    this.titleService.setTitle(`${app.projectName} | ${app.organisationName}`);
  }
}
