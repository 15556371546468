import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { NoopAnimationsModule } from '@angular/platform-browser/animations';
import { ModalModule } from 'ngx-bootstrap/modal';
import {IvyCarouselModule} from 'angular-responsive-carousel';

import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { AppComponent } from './app.component';
import { HomeComponent } from './components/pages/home/home.component';
import { ViewpointComponent } from './components/pages/viewpoint/viewpoint.component';
import { MapComponent } from './components/pages/map/map.component';
import { HorizontalToolbarComponent } from './shared/components/horizontal-toolbar/horizontal-toolbar.component';
import { FooterComponent } from './shared/components/footer/footer.component';
import { HeaderComponent } from './shared/components/header/header.component';
import { CompassComponent } from './shared/components/compass/compass.component';
import { MinimapComponent } from './shared/components/minimap/minimap.component';
import { ViewpointMapComponent } from './shared/components/viewpoint-map/viewpoint-map.component';
import { BackButtonComponent } from './shared/components/back-button/back-button.component';
import { SwitchComponent } from './shared/components/switch/switch.component';
import { ModalDialogComponent } from './shared/components/modal-dialog/modal-dialog.component';
import { AppRoutingModule } from './app-routing.module';
import { TsLogoComponent } from './shared/components/ts-logo/ts-logo.component';
import { HotspotComponent } from './shared/components/hotspot/hotspot.component';
import { SocialLinksComponent } from './shared/components/social-links/social-links.component';
import { NavComponent } from './shared/components/nav/nav.component';
import { SafeHtmlPipe } from './shared/pipes/safe-html.pipe';
import { AbTestComponent } from './shared/components/ab-test/ab-test.component';
import { DarkModeComponent } from './shared/components/dark-mode/dark-mode.component';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { WalkthroughComponent } from './shared/components/walkthrough/walkthrough.component';
import { EasterEggComponent } from './shared/components/easter-egg/easter-egg.component';
import { ProjectNameBoxComponent } from './shared/components/project-name-box/project-name-box.component';
import { InfoBoxComponent } from './shared/components/info-box/info-box.component';
import { ZoomIndicatorComponent } from './shared/components/zoom-indicator/zoom-indicator.component';


@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    ViewpointComponent,
    MapComponent,
    HorizontalToolbarComponent,
    FooterComponent,
    HeaderComponent,
    CompassComponent,
    MinimapComponent,
    ViewpointMapComponent,
    BackButtonComponent,
    SwitchComponent,
    ModalDialogComponent,
    TsLogoComponent,
    HotspotComponent,
    SocialLinksComponent,
    NavComponent,
    SafeHtmlPipe,
    AbTestComponent,
    DarkModeComponent,
    WalkthroughComponent,
    EasterEggComponent,
    ProjectNameBoxComponent,
    InfoBoxComponent,
    ZoomIndicatorComponent
  ],
  imports: [
    BrowserModule,
    NoopAnimationsModule,
    LeafletModule,
    AppRoutingModule,
    IvyCarouselModule,
    HttpClientModule,
    ModalModule.forRoot(),
  ],
  providers: [{ provide: LocationStrategy, useClass: HashLocationStrategy }],
  bootstrap: [AppComponent]
})
export class AppModule { }
