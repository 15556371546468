import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { StateService } from '@shared/services/state.service';
import { Viewpoint } from '@models/viewpoint.model';

@Component({
  selector: 'app-switch',
  templateUrl: './switch.component.html',
  styleUrls: ['./switch.component.scss'],
})
export class SwitchComponent implements OnInit {
  @Input('viewpoint') viewpoint: Viewpoint;
  @Input('checked') checked: boolean = false;
  @Input('overlayChecked') overlayChecked: boolean = false;
  @Input('vegetationChecked') vegetationChecked: boolean = true;
  @Output() check = new EventEmitter();
  @Output() checkLayer = new EventEmitter();
  toggleState: any;
  state: any;

  constructor(stateService: StateService) {
    this.state = stateService.getState();
  }

  ngOnInit(): void {
    if(this.viewpoint){
      this.toggleState = {};
      let options = this.viewpoint.mode.proposed.layers.items;
      options.forEach(option => {
        this.toggleState[option.slug] = {
          checked: option.isChecked
        }
      });
    }

  }

  toggle() {
    this.checked = !this.checked;
    //emit check event to parent viewpoint controller
    this.check.emit({ checked: this.checked });
  }

  toggleLayer(layerName) {
    this.toggleState[layerName].checked = !this.toggleState[layerName].checked;
    this.checkLayer.emit(this.toggleState);
  }
}
