import { Component, OnInit } from '@angular/core';
import { app } from '@shared/data/data.json';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  app: any;
  constructor() {
    this.app = app;
   }

  ngOnInit(): void {
  }

}
