<div #tiles id="tiles"></div>
<h1 class="heading" [innerHTML]="viewpoint.heading"></h1>
<app-back-button [pageName]="'map'"></app-back-button>
<app-switch
  *ngIf="viewpoint.mode['existing']"
  [viewpoint]="viewpoint"
  [checked]="state.global.mode === 'proposed'"
  (check)="toggleScene($event)"
  (checkLayer)="toggleLayerScene($event)"
></app-switch>
<app-dark-mode
  [top]="130"
  (toggleDarkScene)="toggleDarkScene($event)"
  *ngIf="type && viewpoint.mode[type].darkSceneSlug"
></app-dark-mode>
<app-easter-egg
  [top]="160"
  (toggleEasterEggScene)="toggleEasterEggScene($event)"
  *ngIf="type && viewpoint.mode[type].easterEggSceneSlug"
></app-easter-egg>
<app-viewpoint-map
  [viewpoint]="viewpoint"
  [viewpoints]="viewpoints"
  [isMinimized]="true"
  [minZoom]="19"
  [maxZoom]="15"
  [zoom]="16"
></app-viewpoint-map>
<!-- <app-minimap
  [viewer]="viewer"
  [viewpoint]="viewpoint"
  [viewpoints]="viewpoints"
  [zoom]="16"
></app-minimap> -->
<app-compass [viewpoint]="viewpoint" [viewer]="viewer"></app-compass>
<app-zoom-indicator [viewer]="viewer"></app-zoom-indicator>
<app-horizontal-toolbar
  [viewpoint]="viewpoint"
  [viewpoints]="viewpoints"
  [title]="'Viewpoints'"
  [toggleBtnTop]="83"
></app-horizontal-toolbar>
<app-hotspot
  *ngFor="let sceneHotspot of sceneHotspots"
  [scene]="sceneHotspot.scene"
  [data]="sceneHotspot.hotspot"
  [sceneId]="sceneHotspot.sceneId"
></app-hotspot>
<app-walkthrough [data]="walkthrough.externalViewpoints"></app-walkthrough>
